import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  SerializeFrom,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useMatches,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { type ReactNode, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import toastifyStyles from "react-toastify/dist/ReactToastify.css?url";
import { AuthenticityTokenProvider } from "remix-utils/csrf/react";
import { ExternalScripts } from "remix-utils/external-scripts";

import { Analytics } from "~/components/analytics";
import { ChangeContactModal } from "~/components/ChangeContactModal";
import { ErrorComponent } from "~/components/error-boundary";
import { ExpiredPasswordModal } from "~/components/ExpiredPasswordModal/ExpiredPasswordModal";
import { GlobalLoading } from "~/components/GlobalLoading";
import { PasswordExpiresSoonModal } from "~/components/PasswordExpiresSoonModal/PasswordExpiresSoonModal";
import { Footer } from "~/components/ui/footer";
import { Header } from "~/components/ui/header";
import { TooltipProvider } from "~/components/ui/tooltip";
import { SEO_META_DEFAULT_CONFIG } from "~/config/seo";
import { useContactRestrictionToast } from "~/hooks/use-contact-restriction-toast";
import { useExpiredPassword } from "~/hooks/use-expired-password";
import { useGlobalErrorToast } from "~/hooks/use-global-error-toast";
import { useIsClient } from "~/hooks/use-is-client";
import { getNavAdvertisings } from "~/models/advertising.server";
import { getActiveCart } from "~/models/cart.server";
import { getCategories } from "~/models/category.server";
import { getContact } from "~/models/contact.server";
import { getSession, logout, sessionStorage } from "~/models/session.server";
import { getSettings } from "~/models/setting.server";
import { getCurrentUser } from "~/models/user.server";
import globalStyles from "~/styles/global.css?url";
import { useAnalyticsEvents } from "~/utils/analytics";
import { getAnalyticsFromRequest } from "~/utils/analytics.server";
import { api } from "~/utils/api-fetcher.server";
import { commitCsrf } from "~/utils/csrf.server";
import { handleError } from "~/utils/errors.server";
import { combineHeaders } from "~/utils/headers";
import { useNonce } from "~/utils/nonce";
import { getSeoMeta } from "~/utils/seo";
import { parseSessionData } from "~/utils/sessions";

export const meta: MetaFunction = (metaArgs) => {
  return getSeoMeta(metaArgs, {
    title: SEO_META_DEFAULT_CONFIG.title,
    description: SEO_META_DEFAULT_CONFIG.description,
  });
};

const favicons = [
  { rel: "icon", href: "/favicon.ico", type: "image/x-icon" },
  { rel: "icon", href: "/icons/icon-16.png", type: "image/png", sizes: "16x16" },
  { rel: "icon", href: "/icons/icon-32.png", type: "image/png", sizes: "32x32" },
  { rel: "apple-touch-icon", href: "/icons/apple-touch-icon-180.png", sizes: "180x180" },
  { rel: "manifest", href: "/site.webmanifest", crossOrigin: "use-credentials" },
];

const stylesheets = [
  { rel: "stylesheet", href: toastifyStyles },
  { rel: "stylesheet", href: globalStyles },
];

export const links: LinksFunction = () => {
  return [...favicons, ...stylesheets];
};

export type RootLoader = typeof loader;

export const loader = async ({ request }: LoaderFunctionArgs) => {
  try {
    // Redirect if url contains /fr/ (old url format)
    if (request.url.includes("/fr/")) {
      const newUrl = new URL(request.url);
      newUrl.pathname = newUrl.pathname.replace("/fr/", "/");
      return redirect(newUrl.toString(), 301);
    }

    const ENV = {
      VITE_APP_URL: process.env.VITE_APP_URL,
      PORT: process.env.PORT,
      API_URL: process.env.API_URL,
      API_ROOT_URL: process.env.API_ROOT_URL,
      RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
      GOOGLE_SITE_VERIFICATION: process.env.GOOGLE_SITE_VERIFICATION,
      GA_TRACKING_ID: process.env.GA_TRACKING_ID,
      HOTJAR_ID: process.env.HOTJAR_ID,
      AXEPTIO_CLIENT_ID: process.env.AXEPTIO_CLIENT_ID,
      SENTRY_DSN: process.env.SENTRY_DSN,
      SENTRY_HOSTING_ENV: process.env.SENTRY_HOSTING_ENV,
      TRANSLATE_SITE: process.env.TRANSLATE_SITE,
    };

    const [session, user] = await Promise.all([
      parseSessionData(await getSession({ request })),
      getCurrentUser({ request }),
    ]);

    let activeContactId = session.get("activeContactId") ?? null;

    // Logout if active contact is not in user contacts
    if (
      activeContactId &&
      user?.contacts.findIndex((contact) => contact.id === activeContactId) === -1
    ) {
      const path = new URL(request.url).pathname;
      return logout({ redirectTo: path, request });
    }

    // Set active contact if user has only one contact
    if (!activeContactId && user?.contacts.length === 1) {
      activeContactId = user.contacts[0]?.id ?? null;
      session.set("activeContactId", activeContactId);
      session.set("activeCompanyId", user.contacts[0]?.company?.id ?? null);
    }

    // Daily log for active contact
    const lastContactLog = session.get("lastContactLog");
    const isLastContactLogToday =
      !!lastContactLog && new Date(lastContactLog).toDateString() === new Date().toDateString();
    if (activeContactId && !isLastContactLogToday) {
      await api({ request }).post(`/account/login/${activeContactId}`, {});
      session.set("lastContactLog", new Date().toISOString());
    }

    // Get data
    const [settings, navAdvertisings, categories, contact, currentCart] = await Promise.all([
      getSettings({ request }),
      getNavAdvertisings({ request }),
      getCategories({ request }),
      user && activeContactId ? getContact({ request, id: activeContactId }) : null,
      user && activeContactId ? getActiveCart({ request }) : null,
    ]);

    const { csrfToken, csrfCookieHeader } = await commitCsrf();
    const { analyticsEvents, analyticsEventsCookieHeader } = await getAnalyticsFromRequest(request);

    return json(
      {
        ENV,
        settings,
        navAdvertisings,
        activeContactId,
        user,
        contact,
        currentCart,
        comparator: session.get("comparator") || null,
        categories,
        csrfToken,
        isUserConnected: user?.id ? true : false,
        isPasswordExpired: user?.contacts[0]?.passwordExpired || false,
        companyContacts: user?.contacts || null,
        analyticsEvents,
      },
      {
        headers: combineHeaders(csrfCookieHeader, analyticsEventsCookieHeader, {
          "set-cookie": await sessionStorage.commitSession(session),
        }),
      }
    );
  } catch (error) {
    return handleError(error, {
      ENV: null,
      settings: null,
      navAdvertisings: null,
      activeContactId: null,
      user: null,
      contact: null,
      currentCart: null,
      comparator: null,
      categories: null,
      csrfToken: null,
      isUserConnected: false,
      isPasswordExpired: false,
      companyContacts: null,
      analyticsEvents: null,
    });
  }
};

function Document({
  children,
  nonce,
  noIndex = false,
  env = {},
}: {
  children: ReactNode;
  nonce: string;
  noIndex?: boolean;
  env?: Record<string, string> | null;
}) {
  // useEffect(() => {
  //   if (env?.TRANSLATE_SITE === "true" && window && window.weglotInit) {
  //     window.weglotInit();
  //   }
  // }, [env?.TRANSLATE_SITE]);

  const isClient = useIsClient();

  const GTM_ID = import.meta.env.VITE_GTM_ID;

  useEffect(() => {
    if (isClient) {
      if (window.axeptioInit) {
        window?.axeptioInit?.();
      }
      if (GTM_ID) {
        addGtmScript?.(GTM_ID);
      }
    }
  }, [isClient, GTM_ID]);

  return (
    <html lang="fr">
      <head>
        {noIndex ? <meta name="robots" content="noindex" /> : null}
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />

        <Analytics
          axeptioClientId={env?.AXEPTIO_CLIENT_ID}
          gaTrackingId={env?.GA_TRACKING_ID}
          hotjarId={env?.HOTJAR_ID}
          googleSiteVerification={env?.GOOGLE_SITE_VERIFICATION}
          nonce={nonce}
        />

        {/* Weglot translation */}
        {/* {env?.TRANSLATE_SITE === "true" ? (
          <>
            <script
              type="text/javascript"
              nonce={nonce}
              src="https://cdn.weglot.com/weglot.min.js"
            />
            <script
              nonce={nonce}
              dangerouslySetInnerHTML={{
                __html: `
                  function weglotInit() {
                    Weglot.initialize({
                      api_key: "wg_01ba5f2c16c6c99e3a1847e063c2adef1"
                    });
                    console.info("Weglot initialized.");
                  }
                  window.weglotInit = weglotInit;
                `,
              }}
            />
          </>
        ) : null} */}
      </head>
      <body>
        {/* Google Tag Manager */}
        {GTM_ID ? (
          <noscript>
            <iframe
              title="Google Tag Manager"
              src={"https://www.googletagmanager.com/ns.html?id=" + GTM_ID}
              width="0"
              height="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>
        ) : null}

        {/* App */}
        {children}

        {/* Scroll restoration */}
        <ScrollRestoration nonce={nonce} />

        {/* Scripts */}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <Scripts nonce={nonce} />
        <ExternalScripts />
      </body>
    </html>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();

  const {
    isExpiredPasswordModalOpen,
    isPasswordExpiresSoonModalOpen,
    setIsExpiredPasswordModalOpen,
    setIsPasswordExpiresSoonModalOpen,
    setIsPasswordExpiresSoonModalSeen,
  } = useExpiredPassword({ user: data.user, contact: data.contact });

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  useEffect(() => {
    if (data.user && !data.contact && !isExpiredPasswordModalOpen) {
      setIsContactModalOpen(true);
    }
  }, [data.user, data.contact, isExpiredPasswordModalOpen]);

  useGlobalErrorToast();
  useAnalyticsEvents({ pageLoadEvents: data.analyticsEvents });
  useContactRestrictionToast({ contact: data.contact });

  return (
    <Document nonce={nonce} env={data.ENV}>
      {/* Header, Main content and Footer */}
      <div>
        <Header
          user={data.user}
          contact={data.contact}
          categories={data.categories || null}
          currentCart={data.currentCart || null}
          navAdvertisings={data.navAdvertisings || null}
        />

        <main>
          <Outlet />
        </main>

        <Footer categories={data.categories || null} contact={data.contact || null} />
      </div>

      {/* Modals */}
      {isContactModalOpen ? (
        <ChangeContactModal
          activeContact={data.contact}
          contacts={data.user?.contacts || []}
          onClose={() => (data.contact ? setIsContactModalOpen(false) : null)}
          open={isContactModalOpen}
        />
      ) : null}
      {isExpiredPasswordModalOpen ? (
        <ExpiredPasswordModal
          user={data.user}
          onClose={() =>
            data.user?.contacts[0]?.passwordExpired ? null : setIsExpiredPasswordModalOpen(false)
          }
          open={isExpiredPasswordModalOpen}
        />
      ) : null}
      {isPasswordExpiresSoonModalOpen ? (
        <PasswordExpiresSoonModal
          user={data.user}
          onClose={() => {
            setIsPasswordExpiresSoonModalOpen(false);
            setIsPasswordExpiresSoonModalSeen(true);
            window.sessionStorage.setItem("passwordExpiresSoonModalSeen", "true");
          }}
          open={isPasswordExpiresSoonModalOpen}
        />
      ) : null}

      {/* Comparator button */}
      {/* {!hideComparator ? (
        <ComparatorButton
          totalItems={data.comparator?.products?.length || data.comparator?.ranges?.length || null}
        />
      ) : null} */}

      <ToastContainer autoClose={3000} limit={2} position="bottom-center" />
      <GlobalLoading />
    </Document>
  );
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  if (!data.csrfToken) {
    return (
      <TooltipProvider delayDuration={300} skipDelayDuration={500}>
        <App />
      </TooltipProvider>
    );
  }
  return (
    <AuthenticityTokenProvider token={data.csrfToken || ""}>
      <TooltipProvider delayDuration={300} skipDelayDuration={500}>
        <App />
      </TooltipProvider>
    </AuthenticityTokenProvider>
  );
}

export default withSentry(AppWithProviders);

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce} noIndex>
      <ErrorComponent />
    </Document>
  );
}

export function useRootMatchData() {
  const matches = useMatches();
  const match = matches.find((m) => m.id === "root");
  const [data, setData] = useState(match?.data || null);

  useEffect(() => {
    if (match && match.data) {
      setData(match.data);
    }
  }, [match]);

  return data as SerializeFrom<typeof loader> | null;
}

let gtmScriptAdded = false;

function addGtmScript(GTM_ID: string) {
  if (!GTM_ID || gtmScriptAdded) {
    return;
  }

  // Code copied from GTM console + added type annotations.
  (function (w: Window, d: Document, s: "script", l: string, i: string) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement<"script">(s);
    const dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f?.parentNode?.insertBefore(j, f);
  })(window, document, "script", "dataLayer", GTM_ID);

  gtmScriptAdded = true;

  console.info("Google Tag Manager script added.");
  console.info("GTM_ID:", GTM_ID);
  console.info("window.dataLayer:", window.dataLayer);
  console.info("gtmScriptAdded:", gtmScriptAdded);
}
