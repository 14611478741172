import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import { Link } from "@remix-run/react";
import { type ComponentPropsWithoutRef, useMemo } from "react";
import {
  RiArrowLeftSLine,
  RiArrowRightLine,
  RiArrowRightSLine,
  RiCloseLargeLine,
  RiMailLine,
  RiMenuLine,
  RiSettings4Line,
  RiShoppingCartFill,
} from "react-icons/ri";
import type { Advertising } from "~types/api/advertising.types";
import type { Cart } from "~types/api/cart.types";
import type { Category } from "~types/api/category.types";
import type { Contact } from "~types/api/contact.types";
import type { User } from "~types/api/user.types";
import type { CategoryNavigationItem } from "~types/app/navigation.types";

import logoImg from "~/assets/images/logo.png";
import { Searchbar } from "~/components/search";
import { Button } from "~/components/ui/button";
import { Image } from "~/components/ui/image";
import { Wrapper } from "~/components/ui/layout/wrapper";
import { UserMenuButton } from "~/components/ui/user-menu";
import {
  categoriesNavGroups,
  categoriesWithPromoButton,
  categoriesWithRanges,
  headerSecondaryNavItems,
  staticCategoryNavItems,
} from "~/config/navigation";
import { useScrollDirection } from "~/hooks/use-scroll-direction";
import { cn } from "~/utils/classnames";

interface HeaderProps extends ComponentPropsWithoutRef<"header"> {
  categories?: Category[] | null | undefined;
  user: User | null;
  contact: Contact | null;
  currentCart: Cart | null;
  navAdvertisings?: Advertising[] | null;
  className?: string;
}

export function Header({
  categories,
  user,
  contact,
  currentCart,
  navAdvertisings,
  className,
  ...props
}: HeaderProps) {
  const scrollDirection = useScrollDirection();

  const totalCartProducts = Number(
    currentCart?.cartRows?.reduce((acc, row) => acc + Number(row.quantity), 0).toFixed(3) || 0
  );

  const categoryNavItems = useMemo(() => {
    const allItems = staticCategoryNavItems.map((item) => {
      const category = categories?.find((category) => category.slug === item.slug);
      return {
        label: category?.title ?? item.slug,
        brands:
          category?.brands?.map((brand) => ({
            label: brand.title,
            to: categoriesWithRanges.includes(category?.slug ?? "")
              ? `/${category.slug}/gammes?marques=${brand.slug}`
              : `/${category.slug}?marques=${brand.slug}`,
          })) || null,
        order: category?.sort ?? 998,
        children: null,
        advertising: category?.slug
          ? navAdvertisings?.find((ad) => ad?.categories?.find((c) => c.slug === category.slug))
          : null,
        ...item,
      };
    });

    const itemsWithoutChildren =
      allItems.filter((item) =>
        categoriesNavGroups.every((group) => !group.childrenSlugs?.includes(item.slug))
      ) ?? [];

    const itemsWithChildren = categoriesNavGroups.map((group) => {
      const children = allItems.filter((item) => group.childrenSlugs?.includes(item.slug));
      return {
        slug: group.label,
        label: group.label,
        order: children?.[0]?.order ?? 999,
        children: children?.sort((a, b) => a.order - b.order) ?? [],
      };
    });

    return [...itemsWithoutChildren, ...itemsWithChildren].sort((a, b) => a.order - b.order);
  }, [categories, navAdvertisings]);

  return (
    <Disclosure
      as="header"
      className={cn(
        "sticky top-0 z-20 border-b border-b-grey-200 bg-white shadow-sm transition-transform duration-300 ease-in-out",
        scrollDirection === "down"
          ? "[&:not([data-headlessui-state=open])]:max-md:translate-y-[-100%]"
          : "max-md:translate-y-0",
        className
      )}
      {...props}
    >
      {({ open: isMobileNavOpen, close: closeMobileNav }) => (
        <>
          <Wrapper className="flex items-center justify-between gap-4 py-3">
            <DisclosureButton
              as={Button}
              iconOnly
              icon={
                isMobileNavOpen ? (
                  <RiCloseLargeLine className="size-5" />
                ) : (
                  <RiMenuLine className="size-6" />
                )
              }
              variant="link"
              className="2xl:hidden"
            >
              {isMobileNavOpen ? "Fermer le menu des catégories" : "Ouvrir le menu des catégories"}
            </DisclosureButton>

            <Link to="/">
              <Image
                src={logoImg}
                alt="Logo Powr Connect"
                onClick={() => {
                  closeMobileNav();
                }}
                className="max-h-10"
              />
            </Link>

            <Searchbar
              isUserConnected={Boolean(user?.id && contact?.id)}
              className="hidden max-w-sm flex-1 md:block"
            />

            <div className="flex items-center gap-2 2xl:gap-3">
              {user?.role === "ROLE_ADMIN" ? (
                <Button
                  as="link"
                  to="/admin/pages"
                  onClick={() => {
                    closeMobileNav();
                  }}
                  iconOnly
                  icon={<RiSettings4Line />}
                  variant="outline"
                >
                  Administration
                </Button>
              ) : null}
              <Button
                as="link"
                to="/nous-contacter"
                onClick={() => {
                  closeMobileNav();
                }}
                iconOnly
                icon={<RiMailLine />}
                variant="outline"
              >
                Nous contacter
              </Button>

              <UserMenuButton user={user} contact={contact} />

              <Button
                as="link"
                to="/cart"
                onClick={() => {
                  closeMobileNav();
                }}
                iconOnly
                icon={
                  <>
                    <RiShoppingCartFill />
                    {totalCartProducts && totalCartProducts > 0 ? (
                      <span className="absolute right-0 top-0 flex h-5 min-w-5 -translate-y-1/2 translate-x-1/3 items-center justify-center rounded-full border border-grey-900 bg-grey-50 px-1.5 text-[10px] font-semibold text-grey-900">
                        {totalCartProducts}
                      </span>
                    ) : null}
                  </>
                }
                variant="primary"
                className="relative sm:hidden"
              >
                Mon panier
              </Button>
              <Button
                as="link"
                to="/cart"
                onClick={() => {
                  closeMobileNav();
                }}
                icon={<RiShoppingCartFill />}
                variant="primary"
                className="relative hidden sm:inline-flex"
              >
                Mon panier
                {totalCartProducts && totalCartProducts > 0 ? (
                  <span className="absolute right-0 top-0 flex h-5 min-w-5 -translate-y-1/3 translate-x-1/3 items-center justify-center rounded-full border border-grey-900 bg-grey-50 px-1.5 text-xs font-semibold text-grey-900">
                    {totalCartProducts}
                  </span>
                ) : null}
              </Button>
            </div>
          </Wrapper>

          <Wrapper>
            <Searchbar
              isUserConnected={Boolean(user?.id && contact?.id)}
              className="mb-3 border-t border-t-grey-200 pt-3 md:hidden"
            />
          </Wrapper>

          {/* Mobile navigation */}
          <DisclosurePanel
            transition
            unmount={false}
            className="h-screen-without-header absolute inset-x-0 top-full overflow-y-auto border-t border-grey-200 bg-white 2xl:hidden"
          >
            <Wrapper>
              <ul className="space-y-1 py-3">
                {categoryNavItems.map((categoryItem) => (
                  // Categories list
                  <li key={categoryItem.label}>
                    <Popover>
                      {({ open: _isMobileItemOpen, close: closeMobileItem }) => (
                        <>
                          <PopoverButton
                            className={cn(
                              "flex w-full items-center gap-x-3 rounded-lg px-4 py-2 text-left font-medium leading-6 hover:bg-grey-50"
                            )}
                          >
                            {categoryItem.label}
                            <RiArrowRightSLine
                              className={cn("ml-auto size-5 shrink-0 text-grey-500")}
                              aria-hidden="true"
                            />
                          </PopoverButton>

                          <PopoverPanel
                            transition
                            unmount={false}
                            className="absolute inset-0 bg-white transition-transform data-[closed]:translate-x-full"
                          >
                            <Wrapper className="flex items-center gap-2">
                              <PopoverButton
                                className={cn(
                                  "my-1 flex w-full items-center gap-x-3 rounded-lg px-4 py-2 text-left font-medium leading-6 hover:bg-grey-50"
                                )}
                              >
                                <RiArrowLeftSLine
                                  className="size-6 shrink-0 text-grey-500"
                                  aria-hidden="true"
                                />
                                <span className="sr-only">Fermer le sous menu</span>{" "}
                                {categoryItem.label}
                              </PopoverButton>
                            </Wrapper>
                            {categoryItem.children && categoryItem.children.length > 0 ? (
                              // If there are subcategories we display them
                              <Wrapper>
                                <ul className="space-y-1 py-3">
                                  {categoryItem.children.map((subCategory) => (
                                    <li key={subCategory.label}>
                                      <Popover>
                                        {({
                                          open: _isMobileSubItemOpen,
                                          close: closeMobileSubItem,
                                        }) => (
                                          <>
                                            <PopoverButton
                                              className={cn(
                                                "flex w-full items-center gap-x-3 rounded-lg px-4 py-2 text-left font-medium leading-6 hover:bg-grey-50"
                                              )}
                                            >
                                              {subCategory.label}
                                              <RiArrowRightSLine
                                                className={cn(
                                                  "ml-auto size-5 shrink-0 text-grey-500"
                                                )}
                                                aria-hidden="true"
                                              />
                                            </PopoverButton>

                                            <PopoverPanel
                                              transition
                                              unmount={false}
                                              className="absolute inset-0 bg-white transition-transform data-[closed]:translate-x-full"
                                            >
                                              <Wrapper className="flex items-center gap-2">
                                                <PopoverButton
                                                  className={cn(
                                                    "my-1 flex w-full items-center gap-x-3 rounded-lg px-4 py-2 text-left font-medium leading-6 hover:bg-grey-50"
                                                  )}
                                                >
                                                  <RiArrowLeftSLine
                                                    className="size-6 shrink-0 text-grey-500"
                                                    aria-hidden="true"
                                                  />
                                                  <span className="sr-only">
                                                    Fermer le sous menu
                                                  </span>{" "}
                                                  {subCategory.label}
                                                </PopoverButton>
                                              </Wrapper>
                                              <CategoryNavPanel
                                                categoryNavigationItem={subCategory}
                                                onLinkClick={() => {
                                                  closeMobileNav();
                                                  closeMobileItem();
                                                  closeMobileSubItem();
                                                }}
                                              />
                                            </PopoverPanel>
                                          </>
                                        )}
                                      </Popover>
                                    </li>
                                  ))}
                                </ul>
                              </Wrapper>
                            ) : (
                              // If there are no subcategories we display the category content
                              <CategoryNavPanel
                                categoryNavigationItem={categoryItem}
                                onLinkClick={() => {
                                  closeMobileNav();
                                  closeMobileItem();
                                }}
                              />
                            )}
                          </PopoverPanel>
                        </>
                      )}
                    </Popover>
                  </li>
                ))}
              </ul>
            </Wrapper>
          </DisclosurePanel>

          {/* Desktop nav */}
          <Wrapper className="hidden 2xl:block">
            <PopoverGroup
              as="nav"
              className="flex flex-wrap-reverse items-center justify-between gap-x-4 gap-y-2 border-t border-grey-200 py-2"
            >
              <ul className="flex items-center gap-0.5">
                {categoryNavItems.map((categoryItem) => (
                  <Popover as="li" className="group/parent bg-white" key={categoryItem.label}>
                    {({ close: closePopover }) => (
                      <>
                        <PopoverButton
                          as={Button}
                          size="sm"
                          variant="ghost"
                          className="hover:bg-grey-50 focus-visible:ring-0 focus-visible:ring-transparent data-[focus]:outline-none data-[focus]:ring-2 data-[focus]:ring-primary-500 group-data-[open]/parent:bg-grey-100"
                        >
                          {categoryItem.label}
                        </PopoverButton>

                        <PopoverPanel
                          transition
                          unmount={false}
                          className="absolute inset-x-0 top-full origin-top border-b border-grey-200 bg-white shadow-sm transition duration-150 ease-in-out data-[closed]:opacity-0 data-[leave]:delay-75"
                        >
                          {categoryItem.children && categoryItem.children.length > 0 ? (
                            <Wrapper>
                              <PopoverGroup
                                as="ul"
                                className="flex items-center gap-0.5 border-t border-grey-200 py-2"
                              >
                                {categoryItem.children.map((subCategory) => (
                                  <Popover as="li" key={subCategory.label} className="group/child">
                                    {({ close: closeSubPopover }) => (
                                      <>
                                        <PopoverButton
                                          as={Button}
                                          size="sm"
                                          variant="ghost"
                                          className="hover:bg-grey-50 focus-visible:ring-0 focus-visible:ring-transparent data-[focus]:outline-none data-[focus]:ring-2 data-[focus]:ring-primary-500 group-data-[open]/child:bg-grey-100"
                                        >
                                          {subCategory.label}
                                        </PopoverButton>

                                        <PopoverPanel
                                          transition
                                          unmount={false}
                                          className="absolute inset-x-0 top-full origin-top border-b border-grey-200 bg-white shadow-sm transition duration-150 ease-in-out"
                                        >
                                          <CategoryNavPanel
                                            categoryNavigationItem={subCategory}
                                            onLinkClick={() => {
                                              closeMobileNav();
                                              closePopover();
                                              closeSubPopover();
                                            }}
                                          />
                                        </PopoverPanel>
                                      </>
                                    )}
                                  </Popover>
                                ))}
                              </PopoverGroup>
                            </Wrapper>
                          ) : (
                            <CategoryNavPanel
                              categoryNavigationItem={categoryItem}
                              onLinkClick={() => {
                                closeMobileNav();
                                closePopover();
                              }}
                            />
                          )}
                        </PopoverPanel>
                      </>
                    )}
                  </Popover>
                ))}
              </ul>
              {headerSecondaryNavItems && headerSecondaryNavItems.length > 0 ? (
                <ul className="ml-auto flex items-center">
                  {headerSecondaryNavItems.map((item) => (
                    <li key={item.label}>
                      {item.children && item.children.length > 0 ? (
                        <Popover className="group/parent">
                          {({ close: closePopover }) => (
                            <>
                              <PopoverButton
                                as={Button}
                                size="xs"
                                variant="ghost"
                                className="text-grey-500 hover:bg-grey-50 focus-visible:ring-0 focus-visible:ring-transparent data-[focus]:outline-none data-[focus]:ring-2 data-[focus]:ring-primary-500"
                              >
                                {item.label}
                              </PopoverButton>

                              <PopoverPanel
                                transition
                                unmount={false}
                                className="absolute inset-x-0 top-full origin-top border-b border-grey-200 bg-white shadow-sm transition duration-150 ease-in-out data-[closed]:opacity-0 data-[leave]:delay-75"
                              >
                                <Wrapper>
                                  <PopoverGroup
                                    as="ul"
                                    className="flex items-center justify-end gap-0.5 border-t border-grey-200 py-2"
                                  >
                                    {item.children?.map((child) => (
                                      <li key={child.label}>
                                        <Button
                                          as="link"
                                          to={child.to ?? ""}
                                          onClick={() => {
                                            closeMobileNav();
                                            closePopover();
                                          }}
                                          size="xs"
                                          variant="link"
                                          className="text-grey-500"
                                        >
                                          {child.label}
                                        </Button>
                                      </li>
                                    ))}
                                  </PopoverGroup>
                                </Wrapper>
                              </PopoverPanel>
                            </>
                          )}
                        </Popover>
                      ) : (
                        <Button
                          as="link"
                          to={item.to ?? ""}
                          onClick={() => {
                            closeMobileNav();
                          }}
                          size="xs"
                          variant="ghost"
                          className="text-grey-500"
                        >
                          {item.label}
                        </Button>
                      )}
                    </li>
                  ))}
                </ul>
              ) : null}
            </PopoverGroup>
          </Wrapper>
        </>
      )}
    </Disclosure>
  );
}

interface CategoryNavPanelProps {
  categoryNavigationItem: CategoryNavigationItem;
  onLinkClick?: () => void;
}

function CategoryNavPanel({ categoryNavigationItem, onLinkClick }: CategoryNavPanelProps) {
  const columns = [
    { label: "Marques", items: categoryNavigationItem.brands },
    { label: "Type", items: categoryNavigationItem.types },
    { label: "Supports", items: categoryNavigationItem.supports },
    { label: "Top recherches", items: categoryNavigationItem.topSearches },
  ];

  return (
    <Wrapper>
      <div className="grid grid-cols-1 gap-8 border-t border-grey-200 py-6 md:grid-cols-12 md:gap-4">
        <div className="col-span-1 md:col-span-8">
          <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">
            {columns.map((column) =>
              column.items && column.items.length > 0 ? (
                <li key={column.label}>
                  <div className="mb-2 px-4 text-sm font-medium">{column.label}</div>
                  <ul>
                    {column.items?.map((item) => (
                      <li key={item.label}>
                        <Link
                          to={item.to ?? ""}
                          onClick={() => {
                            if (onLinkClick) {
                              onLinkClick();
                            }
                          }}
                          className="block w-full rounded-lg px-4 py-2 text-start text-xs text-grey-600 hover:underline"
                        >
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ) : null
            )}
          </ul>
          <div className="mt-4 flex flex-col items-start gap-2">
            <Button
              as="link"
              to={`/${categoryNavigationItem.slug}`}
              icon={<RiArrowRightLine />}
              iconPosition="right"
              variant="secondary"
              size="sm"
              onClick={() => {
                if (onLinkClick) {
                  onLinkClick();
                }
              }}
            >
              Voir tout
            </Button>
            {categoriesWithPromoButton.includes(categoryNavigationItem.slug) ? (
              <Button
                as="link"
                to={`/${categoryNavigationItem.slug}?filtres=en-promo`}
                variant="accent"
                size="sm"
                onClick={() => {
                  if (onLinkClick) {
                    onLinkClick();
                  }
                }}
              >
                Voir les promos
              </Button>
            ) : null}
          </div>
        </div>

        <div className="col-span-1 md:col-span-4">
          {categoryNavigationItem.advertising?.coverImage ? (
            <div className="relative mx-auto max-w-[400px]">
              <img
                src={categoryNavigationItem.advertising?.coverImage}
                alt="Publicité"
                className="mx-auto aspect-[400/300] w-full rounded-lg border border-grey-200 object-cover"
              />
              <div className="absolute inset-0 flex h-full w-full items-end justify-start p-[6%]">
                {categoryNavigationItem.advertising?.ctaLink &&
                categoryNavigationItem.advertising?.ctaTitle ? (
                  <Button
                    as="link"
                    to={categoryNavigationItem.advertising?.ctaLink ?? ""}
                    target={
                      categoryNavigationItem.advertising?.ctaTarget === "_blank"
                        ? "_blank"
                        : undefined
                    }
                    rel={
                      categoryNavigationItem.advertising?.ctaTarget === "_blank"
                        ? "noopener noreferrer"
                        : undefined
                    }
                    icon={<RiArrowRightLine />}
                    iconPosition="right"
                    variant="outline"
                    onClick={() => {
                      if (onLinkClick) {
                        onLinkClick();
                      }
                    }}
                    className="border-2 border-white text-white"
                  >
                    {categoryNavigationItem.advertising?.ctaTitle}
                  </Button>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
}
