import { Menu, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "@remix-run/react";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import {
  type ComponentPropsWithoutRef,
  Fragment,
  type PropsWithChildren,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { HiOutlineX } from "react-icons/hi";
import { RiSearchLine } from "react-icons/ri";
import {
  Configure,
  Highlight,
  Hits,
  InstantSearch,
  SearchBox,
  useInstantSearch,
} from "react-instantsearch";

import { inputStyles } from "~/components/ui/forms/input";
import { Image } from "~/components/ui/image";
import { useOutsideClick } from "~/hooks/use-outside-click";
import { cn } from "~/utils/classnames";

const searchClient = algoliasearch(
  import.meta.env?.VITE_ALGOLIA_APP_ID ?? "",
  import.meta.env?.VITE_ALGOLIA_SEARCH_KEY ?? ""
);

type SearchProps = ComponentPropsWithoutRef<"div"> & {
  // isMobileOpen: boolean;
  // setIsMobileOpen: (value: boolean) => void;
  isUserConnected?: boolean;
};

export const Searchbar = ({ className }: SearchProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const containerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useOutsideClick(containerRef, () => {
    setIsDropdownOpen(false);
  });

  useEffect(() => {
    setIsDropdownOpen(false);
    formRef.current?.reset();
    formRef.current?.blur();
  }, [location.pathname]);

  return (
    <div ref={containerRef} className={cn("relative w-full", className)}>
      <InstantSearch
        searchClient={searchClient}
        indexName={import.meta?.env?.VITE_ALGOLIA_PRODUCTS_INDEX ?? ""}
        stalledSearchDelay={5000}
        onStateChange={({ uiState, setUiState }) => {
          setUiState(uiState);
          setSearchValue(
            uiState?.[import.meta?.env?.VITE_ALGOLIA_PRODUCTS_INDEX as keyof typeof uiState]
              ?.query ?? ""
          );
        }}
        future={{ preserveSharedStateOnUnmount: true, persistHierarchicalRootCount: true }}
      >
        <Configure hitsPerPage={8} />
        <Menu as="div" className="relative block w-full">
          <SearchBox
            id="header-searchbar"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={false}
            onFocus={() => setIsDropdownOpen(true)}
            onSubmit={() => {
              setIsDropdownOpen(false);
              navigate(`/recherche?search=${searchValue}`);
            }}
            formRef={formRef}
            placeholder="Rechercher un produit ou une ref..."
            resetIconComponent={() => <HiOutlineX className="h-4 w-4" />}
            submitIconComponent={() => <RiSearchLine className="h-4 w-4" />}
            classNames={{
              loadingIndicator: "hidden",
              form: "flex items-center justify-center relative",
              input: inputStyles({
                size: "default",
                iconPosition: "right",
              }),
              submit:
                "absolute top-1 bottom-1 right-1 aspect-square flex items-center justify-center rounded-full bg-grey-100 text-grey-900 hover:bg-grey-200",
              reset:
                "absolute right-10 top-1/2 -translate-y-1/2 p-2 text-grey-500 hover:text-grey-800",
            }}
          />

          <EmptyQueryBoundary fallback={null}>
            <Transition
              show={isDropdownOpen}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems
                static
                className="absolute right-0 z-10 mt-2 w-full origin-top-right divide-y divide-grey-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <Hits
                  hitComponent={(props) => (
                    <SearchHit closeDropdown={() => setIsDropdownOpen(false)} {...props} />
                  )}
                  className="py-1"
                />
              </MenuItems>
            </Transition>
          </EmptyQueryBoundary>
        </Menu>
      </InstantSearch>
    </div>
  );
};

const SearchHit = ({ hit, closeDropdown }: { hit: any; closeDropdown: () => void }) => {
  return (
    <MenuItem>
      <Link
        to={`/${hit.slug}`}
        onClick={() => closeDropdown()}
        className={cn(
          "group flex w-full cursor-pointer items-center gap-4 border-b border-grey-100 px-4 py-2 text-sm text-grey-700 data-[focus]:bg-grey-200"
        )}
      >
        <Image src={hit.image} alt={hit.title} className="h-12 w-12 shrink-0" />
        <div>
          <Highlight
            attribute="title"
            hit={hit}
            classNames={{
              root: "block mt-1 text-md font-semibold w-auto",
              highlighted: "text-primary bg-transparent",
              nonHighlighted: "",
            }}
          />
          <Highlight
            attribute="reference"
            hit={hit}
            classNames={{
              root: "block mt-1.5 text-xs text-grey-700",
              highlighted: "text-primary bg-transparent",
              nonHighlighted: "",
            }}
          />
          <Highlight
            attribute="categories"
            hit={hit}
            classNames={{
              root: "block text-xs text-end text-grey-400",
              highlighted: "text-primary bg-transparent",
              nonHighlighted: "",
            }}
          />
        </div>
      </Link>
    </MenuItem>
  );
};

const EmptyQueryBoundary = ({ children, fallback }: PropsWithChildren<{ fallback: ReactNode }>) => {
  const { indexUiState } = useInstantSearch();

  if (!indexUiState.query) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
};
